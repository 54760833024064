/* cyrillic-ext */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b0059268cc419911-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/38ebcf0298551099-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b53e832d745212bc-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e80e9c14bdf82dd6-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/fbdbe464b9e5cc95-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/07103e16d41c9190-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6dc02f179ba8da8f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9450a5aa688b86af-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f1d4c48219b1bd72-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/931105f8d96e7f26-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bd2489da38fb1d0b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/84a39d905077a976-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0fb93cc812fb4b50-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/32702a3715dbd7c1-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7b9ca6a1d31c5662-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/ef3f4e253ce73be8-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/f0b16be9a95e8820-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/f2988470e2e746be-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/13017c58cf72aedd-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Merriweather_f68def';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/c7a495162773a63f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Merriweather_Fallback_f68def';src: local("Times New Roman");ascent-override: 80.31%;descent-override: 22.28%;line-gap-override: 0.00%;size-adjust: 122.53%
}.__className_f68def {font-family: '__Merriweather_f68def', '__Merriweather_Fallback_f68def';font-style: normal
}.__variable_f68def {--font-merriweather: '__Merriweather_f68def', '__Merriweather_Fallback_f68def'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_b4e02f';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Mulish_Fallback_b4e02f';src: local("Arial");ascent-override: 96.02%;descent-override: 23.89%;line-gap-override: 0.00%;size-adjust: 104.67%
}.__className_b4e02f {font-family: '__Mulish_b4e02f', '__Mulish_Fallback_b4e02f';font-style: normal
}.__variable_b4e02f {--font-mulish: '__Mulish_b4e02f', '__Mulish_Fallback_b4e02f'
}

